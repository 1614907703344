jQuery(document).ready(function ($) {

	$.ajaxSetup({
		headers: {
			'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
		}
	});

	$('a.level').each(function (i, o) {
		var c = $(o).html().replace('kukac', String.fromCharCode(64));
		c = c.replace(RegExp(/pont/g), String.fromCharCode(46));
		$(o).attr('href', 'mai' + 'lt' + 'o:' + c).html(c);
	});

	$('.datepicker').each(function(index, val) {
		var $naptardiv = $(this),
			aktiv_datum = $naptardiv.data("selected");
		$(this).datetimepicker({
			language: 'hu',
			defaultDate: aktiv_datum,
			initialDate: aktiv_datum,
			weekStart: 1,
			autoclose: 1,
			todayHighlight: 1,
			startView: 2,
			minView: 2,
			maxView: 4,
			bootcssVer: 3,
			format: 'yyyy-mm-dd',
		}).on('changeDate', function(ev){
			var m = String(ev.date.getMonth() + 1);
			var d = String(ev.date.getDate());
			document.location = "/tuzrako?date=" + ev.date.getFullYear() + '-' + (m.length == 1 ? "0" : "") + m + '-' + (d.length == 1 ? "0" : "") + d;
		});
	});

});

var recaptcha = function () {
	$('.g-recaptcha').each(function () {
		var $this = $(this);
		grecaptcha.render($this[0], $this.data());
	})
};

function onSubmitKapcsolat(token) {
	document.getElementById("kapcsolatForm").submit();
}

function onSubmitFoglalas(token) {
	document.getElementById("foglalas-form").submit();
}